import {
  DashboardOutlined,
  QuestionCircleOutlined,
  DotChartOutlined,
  FundOutlined,
  FileTextOutlined,
  GitlabOutlined,
  DeliveredProcedureOutlined,
  TeamOutlined,
  MergeCellsOutlined,

  AppstoreOutlined,
  WarningOutlined,
  UserOutlined,

} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from './AppConfig';

const dashBoardNavTree = [
  {
    key: 'dashboards',
    path: `/dashboards`,
    title: 'sidenav.dashboard',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-dashboard',
        app: ['99', '51', '52', '41', '42'],
        path: `/dashboard`,
        title: 'sidenav.dashboard',
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: 'dashboards-myYard',
        app: ['99', '51', '52', '41', '42'],
        path: `/myYard`,
        title: 'sidenav.dashboard.myYard',
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-client',
        app: ['999', '800'],
        path: `/client`,
        title: 'sidenav.dashboard.client',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-attention',
        app: ['99', '51', '52', '41', '42'],
        path: `/attention`,
        title: 'sidenav.dashboard.attention',
        icon: WarningOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-report',
        app: ['99', '51', '52', '41', '42'],
        path: `/report`,
        title: 'sidenav.dashboard.report',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-po',
        app: ['99', '51', '52', '41', '42'],
        path: `/po`,
        title: 'sidenav.dashboard.po',
        icon: MergeCellsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-service',
        path: `/service`,
        app: ['99', '51', '52', '41', '42'],
        title: 'sidenav.dashboard.service',
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: 'dashboards-equipment',
      //   path: `/equipment`,
      //   app: ['99', '51', '52', '41', '42'],
      //   title: 'sidenav.dashboard.equipment',
      //   icon: FileTextOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: 'dashboards-customer',
        app: ['99', '51', '52', '41', '42'],
        path: `/customer`,
        title: 'sidenav.dashboard.customer',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-user',
        path: `/user`,
        app: ['99', '51', '52', '41', '42'],
        title: 'sidenav.dashboard.user',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: 'dashboards-documents',
      //   app: ['99', '51', '52', '41', '42'],
      //   path: `/documents`,
      //   title: 'sidenav.dashboard.documents',
      //   icon: FileTextOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: 'dashboards-location',
        app: ['99', '51', '52', '41', '42'],
        path: `/location`,
        title: 'sidenav.dashboard.location',
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboards-help',
        app: ['99', '51', '52', '41', '42'],
        path: `/help`,
        title: 'sidenav.dashboard.help',
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
