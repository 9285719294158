import React, { useState, useEffect, useRef } from "react";
import { Menu, Layout,Avatar } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from '@ant-design/icons';
import Logo from "../../images/new/logo.svg"
import Circle from "../../images/new/circle_logo.svg"
import arrow from "../../images/new/down_arrow_icon.svg"
import menu from "../../images/new/menu.svg"
import close from "../../images/new/close.svg"
import Register from '../EquipmentCheck/EquipmentCheckIn'
import FoodCheckin from '../EquipmentCheck/FoodCheckin'
import CheckOut from '../EquipmentCheck/Equip_Check_Out'
import Estimate from '../EquipmentCheck/Estimate'
import NavProfile from './NavSearch/NavProfile';
import { connect } from 'react-redux';
import { ToggleNav} from '../../actions/commonAction'
import{useWindowDimensions,IndustryType} from '../../utils/helper'
// import NavLanguage from './NavLanguage';
// import NavPanel from './NavPanel';
// import NavSearch  from './NavSearch';
// import SearchInput from './NavSearch/SearchInput.js'
// import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
// import utils from 'utils'

const { Header } = Layout;

export const HeaderNav = props => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile } = props;
  const [searchActive, setSearchActive] = useState(false)
  const [CompanyName, setCompanyName] = useState('')
  const [userName, setUserName] = useState('');
  const [show, setShow] = useState(false);
  const companyName = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('data')) : '';
  const [profileLogo,setProfileLogo]=useState('')
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    setUserName(localStorage.getItem('userName'))
    if (companyName && companyName?.role_id && companyName.role_id === 999) {
      setCompanyName('Washtrax Admin')
    } else if (companyName && companyName.clientCompany) {
      setCompanyName(companyName.clientCompany)
    }
    const _logo= JSON.parse(localStorage.getItem('data'))?.logo
    setProfileLogo(_logo)
  }, [props.logo])

  useEffect(()=>{
    const _logo= JSON.parse(localStorage.getItem('data')).logo
    setProfileLogo(_logo)
  },[props.changeProfile])
  const onSearchActive = () => {
    setSearchActive(true)
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }
  
  const onToggle = () => {
    props.ToggleNav(toggleCollapsedNav)
    // if(!isMobile) {
    //   toggleCollapsedNav(!navCollapsed)
    // } else {
    //   onMobileNavToggle(!mobileNav)
    // }
  }
  useEffect(()=>{
    if(width<720){
    props.ToggleNav(false)
    }else if(width>720){
      props.ToggleNav(true)
    }
  },[width])

  // const isNavTop = navType === NAV_TYPE_TOP ? true : false
  // const mode = utils.getColorContrast(headerNavColor)
  // const getNavWidth = () => {
  //   if(isNavTop || isMobile) {
  //     return '0px'
  //   }
  //   if(navCollapsed) {
  //     return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  //   } else {
  //     return `${SIDE_NAV_WIDTH}px`
  //   }
  // }
  const checkInRef = useRef();
  const equpment_header_checkin = {
    textTransform: "capitalize",
    paddingTop: "3.5vh",
    cursor: "pointer"
  }
  const checkInHandler = () => {
    checkInRef.current.style.display = "block"
  }
  const checkInCloseHandler = () => {
    checkInRef.current.style.display = "none"
  }
  const userProfileHandler=()=>{
    setShow(!show)
  }
  return (
    <section className="wash_header_section">
      <div className="wash_header_left">
        <div className="wash_header_upload">
          {/* <img src={Circle} alt="upload logo"/> */}
          <img src={profileLogo} alt="upload logo"/>
        </div>
        <div className="wash_header_logo">
        <img src={Logo} alt="upload logo"/>
        </div>
        <div className="wash_mobile_bars" onClick={() => { onToggle() }}><img src={menu} alt="icon"/></div>
      </div>
      <div className="wash_header_right" onClick={userProfileHandler}>
              <div className="wash_header_right_user_img">
                <img src={profileLogo} alt="logo"/>
              </div>
              <div className="wash_header_right_user_text">hello, {userName}</div>
              <div className="wash_header_right_user_down">
                <img src={arrow} alt="logo" style={{transform:show?"rotate(180deg)":""}}/>
              </div>
             {show?<NavProfile/>:""}
      </div>
      </section>


        // {/* <div className="nav"> 
        //   <div style={equpment_header_checkin} onClick={checkInHandler}>
        //     {/* <p style={{ fontSize: "1rem" }}>equip.  check</p> */}
        //   </div>
        //   {/* <Equipment_Check_In assetsCheckIn={checkInRef} click={checkInCloseHandler} /> */}
        //   {/* <EquipmentCheckIn assetsCheckIn={checkInRef} click={checkInCloseHandler} /> */}
        //  {width>1000&&<> <div style={{ marginLeft: "35px" }}>
        //   {(companyName?.role_id && companyName.role_id !== 999 &&typeof window !== 'undefined')&&(IndustryType()!=="Food_Grade"?
        //    <Register clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>EQUIPMENT DROP-OFF</span>} title="EQUIPMENT DROP-OFF" />:<FoodCheckin clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>EQUIPMENT DROP-OFF</span>} title="EQUIPMENT DROP-OFF" />)}   
        //   </div>
        //   <div style={{ marginLeft: "35px" }}>
        //     {companyName?.role_id && companyName.role_id !== 999 && <CheckOut clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>EQUIPMENT PICK-UP</span>} title="EQUIPMENT PICK-UP" />}
        //   </div></>}
          
        //     {/* {companyName?.role_id && companyName.role_id !== 999 && <Estimate clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>Estimate</span>} title="Estimate" />} */}
        // </div> */}
  
  )
}

const mapStateToProps = state => ({
  toggleCollapsedNav:state.common.toggleCollapsedNav,
  logo:state.common.logo
});
export default connect(mapStateToProps, { ToggleNav})(HeaderNav);