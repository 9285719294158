/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// import React from "react"
// import PropTypes from "prop-types"
// import "./layout.css"
// import '../../public/css/index.css';
// import '../../public/css/index.scss'

// const Layout = ({ children }) => {
//   return (
//     <>
//         <main>{children}</main>
//     </>
//   )
// }

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

// export default Layout



import React,{useState,useEffect} from 'react';
import { Layout, Grid, Button } from 'antd';
import "./layout.css"
import './../assets/css/index.css';
import {connect} from 'react-redux';
import './../assets/index.scss';
import AppLocale from "../lang";
import SideNav from './layout-components/SideNav';
import HeaderNav from './layout-components/HeaderNav'
import { IntlProvider } from "react-intl";
import Register from './EquipmentCheck/EquipmentCheckIn'
import CheckOut from './EquipmentCheck/Equip_Check_Out'
// import TopNav from 'components/layout-components/TopNav';
// import MobileNav from 'components/layout-components/MobileNav'
// import HeaderNav from 'components/layout-components/HeaderNav';
// import PageHeader from 'components/layout-components/PageHeader';
// import Footer from 'components/layout-components/Footer';
// import AppViews from 'views/app-views';
import navigationConfig from "../configs/NavigationConfig";
import{IndustryType, useWindowDimensions} from '../utils/helper'
// import { 
//   SIDE_NAV_WIDTH, 
//   SIDE_NAV_COLLAPSED_WIDTH,
//   NAV_TYPE_SIDE,
//   NAV_TYPE_TOP
// } from 'constants/ThemeConstant';
import utils from './../utils';
import FoodCheckin from './EquipmentCheck/FoodCheckin';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = (props) => {
  const currentAppLocale = AppLocale['en'];
  const { height, width } = useWindowDimensions();
  const companyName = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('data')) : '';
  const {children}=props
  const currentRouteInfo = utils.getRouteInfo(navigationConfig,typeof window !=="undefined"? window.location.pathname:'')
  // const screens = utils.getBreakPoint(useBreakpoint());
  // const isMobile = !screens.includes('lg')
  // const isNavSide = navType === NAV_TYPE_SIDE
  // const isNavTop = navType === NAV_TYPE_TOP
  // const getLayoutGutter = () => {
  //   if(isNavTop || isMobile) {
  //     return 0
  //   }
  //   return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  // }
  return (
    <IntlProvider
    locale={currentAppLocale.locale}
    messages={currentAppLocale.messages}>
     <Layout>
      <HeaderNav/>
      {/* {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null} */}
      <Layout className="app-container" >
        <SideNav  routeInfo={currentRouteInfo}/>
        <Layout className="app-layout"  style={{paddingLeft:width>910?props.toggleCollapsedNav?80:250:80,paddingTop:width<650?"70px":""}}>
          <div className={`app-content`}>



          {/* {width<1000&&<div style={{display:'flex',flexDirection:width>494?"row":"column"}}> <div>
          {(companyName?.role_id && companyName.role_id !== 999 &&typeof window !== 'undefined')&&IndustryType()!=="Food_Grade"? <Register clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>EQUIPMENT DROP-OFF</span>} title="EQUIPMENT DROP-OFF" />:<FoodCheckin clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>EQUIPMENT DROP-OFF</span>} title="EQUIPMENT DROP-OFF" />}   
          </div>
          <div style={{ marginLeft:width>494?"25px":0,marginTop:width>494?0:"15px"}}>
            {companyName?.role_id && companyName.role_id !== 999 && <CheckOut clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span style={{width:148}}>EQUIPMENT PICK-UP</span>} title="EQUIPMENT PICK-UP" />}
          </div></div>} */}




            {/* <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} /> */}
            <Content>
              {children}
            </Content>
          </div>
          {/* <Footer /> */}
        </Layout>
      </Layout>
      {/* {isMobile && <MobileNav />} */}
    
    </Layout></IntlProvider>
  )
}

const mapStateToProps = state => ({
  toggleCollapsedNav:state.common.toggleCollapsedNav
});
export default connect(mapStateToProps)(AppLayout);
