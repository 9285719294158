import React from 'react';
import {Layout} from 'antd';
import {Scrollbars} from 'react-custom-scrollbars';
import MenuContent from './MenuContent';
import{useWindowDimensions,IndustryType} from '../../utils/helper'
import {connect} from 'react-redux';
const {Sider} = Layout;

export const SideNav = props => {
  const { height, width } = useWindowDimensions();
  return (
    <Sider
          trigger={null}
      className={`side-nav`}
      width={250}
      collapsed={props.toggleCollapsedNav ? true : false}
      style={{paddingTop:width<650?"83px":""}}
    >

      <Scrollbars autoHide>
        <MenuContent
          //   type={NAV_TYPE_SIDE}
          {...props}
        />
      </Scrollbars>
    </Sider>
  );
};
const mapStateToProps = state => ({
  toggleCollapsedNav: state.common.toggleCollapsedNav,
});
export default connect (mapStateToProps, null) (SideNav);
