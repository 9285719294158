import React, {Component, useState, useEffect, useRef} from 'react';
import {
  Form,
  Input,
  Radio,
  Select,
  Row,
  Upload,
  Col,
  Button,
} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import Footer from '../layout-components/modal/Footer';
import API from '../../api';
import {connect} from 'react-redux';
import {fetchYardList} from '../../actions/yardLocationAction';
import {CustomerList} from '../../actions/customerAction';
import _ from 'lodash';
// import {UserDetails,clientPrimary} from './userform'
var moment = require ('moment-timezone');
const {Option} = Select;

const usePrevious = value => {
  const ref = useRef ();
  useEffect (() => {
    ref.current = value;
  });
  return ref.current;
};
const RegistrationForm = props => {
  const Equipment = [
    {
      name: 'wash_ticket',
      col: 8,
      lable: 'TICKET NO.',
    },
    {
      name: 'equipment#',
      col: 8,
      lable: 'EQUIPMENT #',
    },
    {
      name: 'customer',
      col: 8,
      lable: 'CUSTOMER',
    },
    {
      name: 'from/jobsite',
      col: 8,
      lable: 'FROM/JOBSITE',
    },
    {
      name: 'wash_type',
      col: 8,
      lable: 'WASH TYPE',
    },
    {
      name: 'drop off',
      col: 8,
      lable: 'DROP OFF #',
    },
    {
      name: 'yard_hand',
      col: 8,
      lable: 'YARD HAND',
    },
    {
      name: 'equipment_type',
      col: 8,
      lable: 'EQUIPMENT TYPE',
    },
    {
      name: 'dt_check_in',
      col: 8,
      lable: 'DT CHECK IN',
    },
    {
      name: '10 days_alert',
      col: 8,
      lable: '10 DAYS ALERT',
    },
    {
      name: 'wash_status',
      col: 8,
      lable: 'WASH STATUS',
    },
    {
      name: 'pick_up',
      col: 8,
      lable: 'PICK UP',
    },
    {
      name: 'inspection notes',
      col: 8,
      lable: 'INSPECTION NOTES',
    },
    {
      name: 'images',
      col: 8,
      lable: 'IMAGES (PRE/POST)',
    },
    {
      name: 'manager_notes',
      col: 8,
      lable: 'MANAGER NOTES',
    },
  ];

  const [form] = Form.useForm ();
  const [equipment, setEquipment] = useState (Equipment);
  const [visible, setVisible] = useState (false);
  const [btnDisable, setBtnDisable] = useState (false);
  const {getUserDetails, poNumber} = API;
  const {yardLocationData} = props;
  const prevYard = usePrevious (yardLocationData);
  useEffect (
    () => {
      if (props.title === 'Edit User' && props.user_id !== undefined) {
        getUserDetails (props.user_id).then (res => {
          const _data = {
            ...res.data.data,
            role_id: res.data.data.role_id.toString (),
          };
          form.setFieldsValue (_data);
        });
      }
    },
    [props.user_id, props.title]
  );
  {
    /*useEffect(() => {
        const yard = props.yardLocationData;
        const customer = props.customerList
        const callback = (val) => {
        }
        if (visible === true) {
            const tabParam = JSON.parse(localStorage.getItem('data'))
            const eq = [...equipment]
            tabParam.TabParams && tabParam.TabParams.map((data) => {
                eq.map((item) => {
                    if (item.name === "equipment_type" && data.param_key === 'equipment_type') {
                        item.value.push({ value: data.param_value.toString(), displayValue: data.param_name })
                    }
                })
                setEquipment(eq)
            })
            if (yard.length === 0) {
                props.fetchYardList()
            }
            if (customer === null) {
                props.CustomerList(callback)
            }
        }
    }, [visible])*/
  }

  {
    /*useEffect(() => {
        if (_.isEqual(prevCustomer, customerList) === false) {
            const customer = customerList;
            const eq = [...equipment]
            customer && customer.map((data) => {
                eq.map((item) => {
                    if (item.name === "customer") {
                        item.value.push({ value: data.clients_cust_id.toString(), displayValue: data.name })
                    }
                })
            })

            setEquipment(eq)
        }

    }, [props.customerList])*/
  }
  useEffect (
    () => {
      const yard = yardLocationData;
      if (_.isEqual (prevYard, yardLocationData) === false) {
        const eq = [...equipment];
        yard &&
          yard.map (data => {
            eq.map (item => {
              if (item.name === 'yard_location') {
                item.value.push ({
                  value: data.id.toString (),
                  displayValue: data.address,
                });
              }
            });
          });
        setEquipment (eq);
      }
    },
    [yardLocationData]
  );

  const dropDownSelect = (e, value) => {
    const eq = [...equipment];
    eq.map (item => {
      if (item.name === 'po') {
        item.value = [];
      }
    });
    if (value === 'customer') {
      poNumber (e).then (res => {
        const _data = res.data.data;
        _data &&
          _data.map (data => {
            eq.map (item => {
              if (item.name === 'po') {
                item.value.push ({
                  value: data.po_id.toString (),
                  displayValue: data.po_no,
                });
              }
            });
          });
        setEquipment (eq);
      });
    }
  };

  const handleSource = e => {
    const eq = [...equipment];

    eq.map (item => {
      if (item.name === 'hazardous') {
        if (e.target.value === 'hazardous') {
          item.disabled = false;
          item.rules.map (rules => {
            rules.required = true;
          });
        } else {
          item.disabled = true;
          item.rules.map (rules => {
            rules.required = false;
          });
        }
      }
    });
    setEquipment (eq);
  };

  const onFinish = values => {
    setBtnDisable (true);
  };


  return (
    <Footer
      setVisible={e => setVisible (e)}
      visible={visible}
      Button={props.Button}
      title={props.title}
      buttonColor={props.buttonColor}
      submit={
        <Form.Item>
          <Button
            disabled={btnDisable}
            type="primary"
            form={props.user_id ? 'editUser' : 'newUser'}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      }
    >

      <Form
        form={form}
        id={props.user_id ? 'editUser' : 'newUser'}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={24}>
          {equipment &&
            equipment.map (form => (
              <Col span={form.col}>
                <Form.Item
                  labelCol={{span: 24}}
                  wrapperCol={{span: 24}}
                  name={form.name}
                  label={
                    <span>
                      {form.lable}
                    </span>
                  }
                  initialValue={form.initialValue ? form.initialValue : ''}
                  // initialValue={form.initialValue?form.initialValue:''}
                  rules={form.rules}
                >
                  {form.type === 'dropdown'
                    ? <Select
                    virtual={false}
                        placeholder="Select Role"
                        style={form.style}
                        onChange={e => dropDownSelect (e, form.name)}
                      >
                        {form.value &&
                          form.value.map (item => (
                            <Option value={item.value}>
                              {item.displayValue}
                            </Option>
                          ))}
                      </Select>
                    : form.type === 'radio'
                        ? <Radio.Group onChange={e => handleSource (e)}>
                            {form.value.map (item => (
                              <Radio value={item.value}>
                                {item.displayValue}
                              </Radio>
                            ))}
                          </Radio.Group>
                        : form.type === 'textArea'
                            ? <Input.TextArea style={{height: 87}} />
                            : form.type === 'upload'
                                ? <Upload
                                    name="logo"
                                    action="/upload.do"
                                    listType="picture"
                                  >
                                    <Button icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Upload>
                                : <Input disabled={form.disabled} />}
                </Form.Item>
              </Col>
            ))}

          {/*confirmation && confirmation.map((form) => (
                        <Col span={form.col}>
                            <Form.Item
                                //   labelCol={{span: 24}}
                                //   wrapperCol={{span: 24}}
                                name={form.name}
                                label={
                                    <span>
                                        {form.lable}
                                    </span>
                                }
                                initialValue={form.initialValue ? form.initialValue : ''}
                                // initialValue={form.initialValue?form.initialValue:''}
                                rules={form.rules}
                            >
                                <Input />
                            </Form.Item>
                            </Col>))*/}
        </Row>
        <div className="estimate_grid">
          <Row
            style={{
              border: '1px solid #cccccc',
              borderBottom: 'none',
              padding: '5px',
            }}
          >
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
          </Row>
          <Row
            style={{
              border: '1px solid #cccccc',
              borderBottom: 'none',
              padding: '5px',
            }}
          >
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
          </Row>
          <Row
            style={{
              border: '1px solid #cccccc',
              borderBottom: 'none',
              padding: '5px',
            }}
          >
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
          </Row>
          <Row
            style={{
              border: '1px solid #cccccc',
              borderBottom: 'none',
              padding: '5px',
            }}
          >
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
            <Col span={8}>col-8</Col>
          </Row>
          <Row style={{border: '1px solid #cccccc', padding: '10px'}}>
            <Col span={8} />
            <Col span={8} />
            <Col span={8}>col-8</Col>
          </Row>
        </div>

      </Form>
      <style>
        {`
          .drop-off{
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
          }
         .drop-off div{
            display: flex;
            justify-content: center;
            align-items: center;
         } 
          
          `}
      </style>
    </Footer>
  );
};

export class Estimate extends Component {
  render () {
    return <RegistrationForm {...this.props} />;
  }
}
const mapStateToProps = state => ({
  yardLocationData: state.yardLocationData.yardLocationData,
  customerList: state.customer.customerList,
});

export default connect (mapStateToProps, {fetchYardList, CustomerList}) (
  Estimate
);
