import React, { Component, useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Radio,
  Cascader,
  Select,
  Row,
  Upload,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  Divider,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Footer from '../layout-components/modal/Footer';
import API from '../../api';
import { connect } from 'react-redux';
import { fetchYardList } from '../../actions/yardLocationAction';
import {getEquipmentPickup} from '../../actions/dashboardAction'
import { CustomerList } from '../../actions/customerAction';
import {getClosedOutList,getInYardList,deleteByAssetTxnsId} from '../../actions/myYardAction'
import _ from 'lodash';
import { assendingSort, IndustryType, TextUpperCase,useWindowDimensions } from '../../utils/helper';
// import {UserDetails,clientPrimary} from './userform'
const { Option } = Select;
const RegistrationForm = (props) => {
  const Equipment = [
    {
      name: 'equipment_number',
      lable: 'Equipment No.',
      col: 8,
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select equipment number!',
          whitespace: true,
        },
      ],
    },
    // {
    //   name: 'equipment_type',
    //   disabled:true,
    //   col: 8,
    //   lable: 'Equipment Type',
    //   type: 'dropdown',
    //   value: [],
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Please Select equipment type!',
    //       whitespace: true,
    //     },
    //   ],
    // },
    // {
    //   name: 'equipment_type',
    //   disabled:true,
    //   col: 8,
    //   lable: 'Equipment Sub Type',
    //   type: 'dropdown',
    //   value: [],
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Please Select equipment type!',
    //       whitespace: true,
    //     },
    //   ],
    // },
    {
      name: 'customer',
      disabled:true,
      lable: 'Customer Name',
      col: 8,
    },
    {
      name: 'washout',
      lable: 'Washout Ticket',
      disabled:true,
      col: 8,
    },
    {
      name: 'truck_carrier',
      lable: 'Trucking',
      col: 8,
    },

    // {
    //     name: 'washout certification',
    //     lable: 'WASHOUT CERTIFICATION',
    //     type: 'dropdown',
    //     value: [],
    //     col: 12,
    //     rules: [
    //         {
    //             required: false,
    //             message: 'Please Enter washout certification!',
    //             whitespace: true,
    //         },
    //     ]
    // },
    // {
    //     name: 'washout certification',
    //     lable: 'WASHOUT CERTIFICATION',
    //     type: 'upload',
    //     col: 12,
    //     rules: [
    //         {
    //             // required: true,
    //             message: 'Please upload washout certification!',
    //             whitespace: true,
    //         },
    //     ]
    // },
  ];

  const confirmation = [
    {
      name: 'name',
      placeholder:'Enter Contact Person Name',
      lable: 'Contact Person Name',
      col: 8,
    },
    {
      name: 'phone',
      placeholder:'Enter Phone No.',
      lable: 'Phone No.',
      col: 8,
    },
    {
      name: 'email',
      placeholder:'Enter Email Id',
      lable: 'Email Id',
      col: 8,
    },
    // {
    //   name: 'number',
    //   placeholder:'PHONE',
    //   lable: 'OR',
    // },
  ];

  const [form] = Form.useForm();
  const [equipment, setEquipment] = useState(Equipment);
  const [visible, setVisible] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [assetTxnsId,setAssetTxnsId]=useState('');
  const {checkedinEquipment,equipmentCheckout,mailWithOutAttachment} = API;
  const [clientDetails,setClientDetails] = useState(null);
  const [yardName,setYardName] = useState(null);
  const { height, width } = useWindowDimensions();
    useEffect(()=>{
      if(visible===true){
        fetchEqNo()
    }else{
      form.resetFields()
      const eq = [...equipment];
      eq.map((item) => {
        if (item.name === 'equipment_number') {
          item.value=[];
        }
      });
    }
    
    },[visible])

    const fetchEqNo=()=>{
      const eq = [...equipment];
      checkedinEquipment().then((res)=>{
        const _data=res.data.data
        const eqNo=[];
      
     
        _data &&
        _data.map((data) => {
          eqNo.push({
            value: data.equipment_no,
            displayValue: data.equipment_no,
            customerName:data.customer_details.name,
            clients_cust_id:data.customer_details.clients_cust_id,
            equipmentType: data.equipment_type_details.param_name,
            ticket_ref_id:data.ticket_details?.ticket_ref_id,
            asset_txns_id:data.asset_txns.asset_txns_id,
            yardName:data.asset_txns.yard_details.name
          });
         
        });
        eq.map((item) => {
          if (item.name === 'equipment_number') {
            item.value=eqNo;
          }
        });
        assendingSort(eqNo)
      setEquipment(eq);
      })
      const tabParam = JSON.parse(localStorage.getItem('data'));
      setClientDetails({
        clientName: tabParam.first_name,
        clientCompany: tabParam.clientCompany,
        email: tabParam.email,
        phone: tabParam.mobile,
      })
    }

  const dropDownSelect = (e, value) => {
    const eq = [...equipment];
    eq.map((item) => {
      if (item.name === 'po') {
        item.value = [];
      }
    });
    if (value === 'equipment_number') {
      fetchEqNo()
      eq.map((item)=>{
        if(item.name==="equipment_number"){
          item.value.map((_item)=>{
            if(_item.value===e){
              setAssetTxnsId(_item.asset_txns_id)
              setYardName(_item.yardName)
              
              form.setFieldsValue({equipment_type:_item.equipmentType,
                customer:_item.customerName,
                washout:_item.ticket_ref_id,
             
              })
          }
          })
         
        }
      })
        setEquipment(eq);
    }
  };


  const onFinish = (values) => {
   
    setBtnDisable(true);
    
    const data= {
      "asset_txns_id":assetTxnsId,
      "details":[
          {"key":"pickup_carrier","value":values.truck_carrier},
          {"key":"pickup_driver_name","value":values.driver},
          {"key":"pickup_email","value":values.email},
          {"key":"pickup_mobile","value":values.number}
      ]
  }
    equipmentCheckout(data).then(async(res)=>{
      if(res.data.success===true){
        props.getClosedOutList(0,'');
        props.deleteByAssetTxnsId('assetList',assetTxnsId);
        props.deleteByAssetTxnsId('inYardList',assetTxnsId);
        props.getEquipmentPickup()
      setBtnDisable(false)
      form.resetFields()
      setVisible(false)

      if(values.email){
        await mailWithOutAttachment({
          to: values.email,
          from: 'info@washtrax.com',
          subject: 'EQUIPMENT PICKED UP',
          client_manager: clientDetails.clientName,
          client_name: clientDetails.clientCompany,
          client_manager_email: clientDetails.email,
          client_manager_phone: clientDetails.phone,
          type: 'pickup',
          equipment_no: values.equipment_number,
          yardAddress:yardName
        });
        }
    }else{
        setBtnDisable(false)
        
       
      }
    }).catch((err)=>{
      setBtnDisable(false)
    })
    
  };
  
  return (
    <Footer
    fullWidth={width<991?true:false}
      setVisible={(e) => setVisible(e)}
      visible={visible}
      Button={props.Button}
      title={props.title}
      className={props.className}
      custom={props.custom}
      buttonColor={props.buttonColor}
      submit={
        <Form.Item>
          <Button
            disabled={btnDisable}
            type="primary"
            form={'eqCheckout'}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      }
    >
      <Form
        form={form}
        id="eqCheckout"
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={24}>
          {equipment &&
            equipment.map((form) => (
               <Col span={form.col}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={form.name}
                  label={<span>{form.lable}</span>}
                  initialValue={form.initialValue ? form.initialValue : ''}
                  // initialValue={form.initialValue?form.initialValue:''}
                  rules={form.rules}
                >
                  {form.type === 'dropdown' ? (
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    virtual={false}
                    allowClear={false}
                    disabled={form.disabled} 
                      style={form.style}      
                      onChange={(e) => dropDownSelect(e, form.name)}
                    >
                      {form.value &&
                        form.value.map((item) => (
                          <Option value={item.value} className="text-uppercase">
                            {item.displayValue}
                          </Option>
                        ))}
                    </Select>
                  )  : form.type === 'textArea' ? (
                    <Input.TextArea style={{ height: 87 }} />
                  ) : (
                    <Input disabled={form.disabled} />
                  )}
                </Form.Item>
              </Col>
            ))}
             <Col span={24}>
      <div style={{fontWeight:'600'}}>Provide drop off confirmation</div>
   <div style={{width: '100%',borderBottom: '0.0001rem solid #c6c2c2',margin: '8px 0px 34px 0px'}}></div>
   </Col>

          {confirmation &&
            confirmation.map((form) => (
              <Col span={form.col}>
                <Form.Item
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                  name={form.name}  
                  label={<span>{form.lable}</span>}
                  initialValue={form.initialValue ? form.initialValue : ''}
                  // initialValue={form.initialValue?form.initialValue:''}
                  rules={form.rules}
                >
                  <Input    placeholder={form.placeholder} />
                </Form.Item>
              </Col>
            ))}
        </Row>
      </Form>
      <style>
        {`
          .drop-off{
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
          }
         .drop-off div{
            display: flex;
            justify-content: center;
            align-items: center;
         } 
          
          `}
      </style>
    </Footer>
  );
};

export class CheckOut extends Component {
  render() {
    return <RegistrationForm {...this.props} />;
  }
}
const mapStateToProps = (state) => ({
  yardLocationData: state.yardLocationData.yardLocationData,
  customerList: state.customer.customerList,
  assetPage:state.myYard.assetPage,
  inYardPage:state.myYard.inYardPage,
	closedOutPage:state.myYard.closedOutPage
});

export default connect(mapStateToProps, { fetchYardList,deleteByAssetTxnsId, CustomerList,getClosedOutList,getInYardList,getEquipmentPickup })(
  CheckOut
);
